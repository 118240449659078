import axios from 'axios';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';

function RegisterMain() {
    const nav = useNavigate();

    const [name, setname] = useState('');
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const [password_confirmation, setpassword_confirmation] = useState('');

    const handleRegister = async () => {

        if ( !email ) {
            alert("Please fill the email!");
            return false;
        }

        if ( !password ) {
            alert("Please fill the password!");
            return false;
        }

        if ( !password_confirmation ) {
            alert("Please fill the password confirmation!");
            return false;
        }

        if ( password !== password_confirmation ) {
            alert("Password doesnt match!");
            return false;
        }

        const res = await axios({
            method: "POST",
            url: process.env.REACT_APP_API+"register",
            data: {
                name: name,
                email: email,
                password: password,
                password_confirmation: password_confirmation,
            }
        });

        if ( res.data.code == '200') {
            setname('');
            setemail('');
            setpassword('');
            setpassword_confirmation('');
            alert(res.data.message);
        } else {
            alert(res.data.message);
        }
    }

  return (
    <>
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-md-5'>
                    <div className='mt-3'>
                        <label>Full Name</label>
                        <input 
                            className='form-control'
                            type='text'
                            value={name}
                            onChange={ (e) => setname(e.target.value) }
                        />
                    </div>

                    <div className='mt-3'>
                        <label>Email</label>
                        <input 
                            className='form-control'
                            type='email'
                            value={email}
                            onChange={ (e) => setemail(e.target.value) }
                        />
                    </div>

                    <div className='mt-3'>
                        <label>Password</label>
                        <input 
                            className='form-control'
                            type='password'
                            value={password}
                            onChange={ (e) => setpassword(e.target.value) }
                        />
                    </div>

                    <div className='mt-3'>
                        <label>Password Confirmation</label>
                        <input 
                            className='form-control'
                            type='password'
                            value={password_confirmation}
                            onChange={ (e) => setpassword_confirmation(e.target.value) }
                        />
                    </div>

                    <div className='mt-3'>
                        <div className='row'>
                            <div className='col'>
                                <button type='button' className='btn btn-primary' onClick={ handleRegister }>REGISTER</button>
                                <Link to='/login' className='btn btn-secondadary'>LOGIN</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default RegisterMain