import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function MidlewareAuth(props) {

    const nav = useNavigate();

    useEffect( () => {
        if ( !localStorage.getItem('token') ) {
            nav("/login");
        }
    }, [])
    
    return props.children

}
