import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function MidlewareGuest(props) {

    const nav = useNavigate();

    useEffect( () => {
      if ( localStorage.getItem('token') !== null ) {
          nav("/dashboard");
      }
      
    }, [])

    return props.children
}
