import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { numberWithCommas, title_web } from '../../components/Constants'
import NavbarV1 from '../../components/navbar/NavbarV1'
import axios from "axios";
import DatatablePage from './DatatablePage';
import AppDatatable from '../../components/AppDatatable';
import AppLoader from '../../components/AppLoader';

function TransactionMain() {
    const [loader, setloader ] = useState(false);
    const nav = useNavigate();

    const [ title, settitle ] = useState('');
    const [ amount, setamount ] = useState('');
    const [ amountError, setamountError ] = useState('');
    const [ type, settype ] = useState('');
    const [ note, setnote ] = useState('');
    const [ dataBalance, setdataBalance] = useState([]);

    const handleAdd = () => {

        // VALDIASI
        if ( !title ) {
            alert("Please fill the title");
            return false;
        }

        if ( amount <= 0 ) {
            alert("Please check amount field");
            return false;
        }

        if ( window.confirm("Apa kamu yakin ingin membuat transaksi ( "+ note+" )") ) {
            fnAddTransaction();
        } else {
            console.log("Membatalkan transaksi")
        }
    }

    const fnAddTransaction = async () => {
        setloader(true);
        try {
            const res = await axios({
                method: 'POST',
                url: process.env.REACT_APP_API+'user-transaction/addTransaction',
                data: {
                    title: title,
                    amount: amount,
                    type: type,
                    note: note,
                    user_id: localStorage.getItem('id'),
                }
            })

            setloader(false);
            if (res.data.code == '200') {
                alert(res.data.message);
                resetFormTransaction();
                getMyBalance();
            } else {
                alert(res.data.message);
            }
        } catch (error) {
            setloader(false);
            alert(error)
        }
    }

    const resetFormTransaction = () => {
        settitle('')
        setamount('')
        settype('')
        setnote('')
    }

    const getMyBalance = async () => {
        try {
            const res = await axios({
                method: 'POST',
                url: process.env.REACT_APP_API+'user-transaction/getMyBalance',
                data: {
                    user_id: localStorage.getItem('id')
                }
            });
    
            if ( res.data.code == '200') {
                if (res.data.data.balance.length > 0 ) {
                    setdataBalance({
                        columns: [
                            {
                                label: 'Transaction ID',
                                field: 'balance_id',
                            },
                            {
                                label: 'Amount',
                                field: 'amount',
                            },
                            {
                                label: 'Type',
                                field: 'type',
                            },
                            {
                                label: 'Note',
                                field: 'note',
                            },
                            {
                                label: 'Date',
                                field: 'sys_created_on',
                            },
                        ],
                        rows: [
    
                            ...res.data.data.balance.map((v,i) => (
                                {
                                    balance_id: v.balance_id,
                                    amount: numberWithCommas(v.amount),
                                    type: v.type,
                                    note: v.note,
                                    sys_created_on: v.sys_created_on,
                                }
                            ))
                        ],
                    })
                } else {

                }

                console.log(res.data.message);
            } else {
                alert(res.data.message);
            }
        } catch (error) {
            alert(error);
        }
    }

    useEffect( () => {
        getMyBalance();

    }, [])

    if ( loader ) {
        return (
            <AppLoader />
        )
    }

  return (
    <>
        <NavbarV1 />
        <div className='container'>
            <div className='row'>
                <div className='col-md-12 mt-3'>
                    <div className='card bg-white'>
                        <div className='card-body'>
                            <h2>Hi, { localStorage.getItem('name')}</h2>
                            <p>Welcome back to your <b className='text-info'>Transaction Page</b></p>
                            <p>On this page you can create transaction, and get history your transaction</p>
                        </div>
                    </div>
                </div>
            </ div>
            <div className='row mt-3'>
                <div className='col-md-4 mb-3'>
                    <div className='card'>
                        <div className='card-body shadow'>
                            <h3 className='fs-5'>Add Transaction</h3>
                            <div className='mt-3'>
                                <label>TITLE</label>
                                <input class="form-control" 
                                    type='text' 
                                    value={title}
                                    onChange={ (e) => settitle(e.target.value)}
                                    placeholder="TITLE"
                                />
                            </div>

                            <div className='mt-3'>
                                <label>AMOUNT ({ numberWithCommas(amount) })</label>
                                <input class="form-control"  
                                    min={0}
                                    type='number' 
                                    value={amount}
                                    onChange={ (e) => {
                                        setamount(e.target.value)
                                    }}
                                    placeholder="AMOUNT"
                                />
                                <span className='text-danger'>{ amountError }</span>
                            </div>

                            <div className='mt-3'>
                                <label>TYPE</label>
                                <select className='form-control' value={type} onChange={ (e) =>  settype(e.target.value) }>
                                    <option value="select">-- SELECT --</option>
                                    <option value="INCOME">INCOME</option>
                                    <option value="EXPENSES">EXPENSES</option>
                                </select>
                            </div>

                            <div className='mt-3'>
                                <label>Note</label>
                                <textarea value={note} class="textarea form-control" placeholder="e.g. Belanja bulanan" onChange={ (e) => setnote(e.target.value) }>
                                </textarea>
                            </div>

                            <div className='mt-3'>
                                <button type='button' className='form-control btn btn-primary' onClick={ handleAdd}>Add</button>
                                <button type='button' className='form-control btn btn-secondary mt-3' onClick={ () => nav(-1) }>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-8'>
                    <div className='card'>
                        <div className='card-body shadow'>
                            <div className='table-responsive'>
                                <h3>Transaction History</h3>
                                <AppDatatable 
                                    data={ dataBalance }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default TransactionMain