import React from 'react'
import { ProgressBar } from  'react-loader-spinner'
import { primaryColor } from './Constants'

export default function AppLoader() {
  return (
    <>
        {/* <div>
            <Placeholder.Paragraph rows={8} />
            <Loader backdrop  size="lg"  content="loading..." vertical />
        </div> */}
        <div className='d-flex justify-content-center align-items-center' style={{ height: '100vh'}}>
            <ProgressBar
                height="80"
                width="80"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                borderColor = '#0a53be'
                barColor = {primaryColor}
            />
        </div>
    </>
  )
}
