import axios from 'axios';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import AppLoader from '../../components/AppLoader';

function LoginMain() {
    const [loader, setloader] = useState(false);
    const nav = useNavigate();

    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const [password_confirmation, setpassword_confirmation] = useState('');

    const handleLogin = async () => {
        setloader(true);
        if ( !email ) {
            alert("Please fill the email!");
            return false;
        }

        if ( !password ) {
            alert("Please fill the password!");
            return false;
        }

        const res = await axios({
            method: "POST",
            url: process.env.REACT_APP_API+"login",
            data: {
                email: email,
                password: password,
            }
        });

        setloader(false);
        if ( res.data.code == '200') {
            localStorage.setItem("email", res.data.user.email)
            localStorage.setItem("name", res.data.user.name)
            localStorage.setItem("token", res.data.token)
            localStorage.setItem("id", res.data.user.id)
            nav("/dashboard");
        } else {
            nav.navigate("/error");
        }
    }

    if ( loader ) {
        return (
            <AppLoader />
        )
    }

  return (
    <>
        <div className='container'>
            <div className='row justify-content-center mt-5'>
                <div className='col-md-5'>
                    <div className=''>
                        <h1 className='fs-6'>Manage your money, your income, your expenses is <span className='text-primary'> so easy</span>. </h1>
                        <p className='fw-bold text-primary' style={{ fontSize: 40 }}>Sign In Now!</p>
                    </div>
                    <div className='mt-3'>
                        <label>Email</label>
                        <input 
                            className='form-control'
                            type='email'
                            value={email}
                            onChange={ (e) => setemail(e.target.value) }
                        />
                    </div>

                    <div className='mt-3'>
                        <label>Password</label>
                        <input 
                            className='form-control'
                            type='password'
                            value={password}
                            onChange={ (e) => setpassword(e.target.value) }
                        />
                    </div>

                    <div className='mt-3'>
                        <div className='row'>
                            <div className='col'>
                                <button type='button' className='btn btn-primary' onClick={ handleLogin }>LOGIN</button>
                                <Link to='/register' className='btn btn-secondadary'>REGISTER</Link>
                            </div>

                            <div className='col'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default LoginMain