import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { numberWithCommas, title_web } from '../../components/Constants'
import NavbarV1 from '../../components/navbar/NavbarV1'

export default function HomeMain() {
    const user_name = localStorage.getItem('name');
    const user_id = localStorage.getItem('id');
    const user_email = localStorage.getItem('email');

    const [currentBalance, setcurrentBalance] = useState(0);
    const [currentIncome, setcurrentIncome] = useState(0);
    const [currentExpenses, setcurrentExpenses] = useState(0);

    // FUNCTION 
    const getMyBalance = async () => {
        try {
            const res = await axios({
                method: 'POST',
                url: process.env.REACT_APP_API+'user-transaction/getMyBalance',
                data: {
                    user_id: user_id
                }
            });
    
            if ( res.data.code == '200') {
                if (res.data.data.balance.length > 0 ) {
                    setcurrentBalance(res.data.data.balance[0].last_balance)
                    setcurrentIncome(res.data.data.total_income)
                    setcurrentExpenses(res.data.data.total_expenses)
                } else {
                    setcurrentBalance(0)
                    setcurrentIncome(0)
                    setcurrentExpenses(0)
                }

                console.log(res.data.message);
            } else {
                alert(res.data.message);
            }
        } catch (error) {
            alert(error);
        }
    }

    useEffect( () => {
        getMyBalance();
    }, [])

  return (
    <>
        <NavbarV1 />
        <div className='container'>
            <div className='row'>
                <div className='col-md-12 mt-3'>
                    <div className='card bg-white'>
                        <div className='card-body'>
                            <h2>Hi, {user_name}</h2>
                            <p>Welcome back to your <b className='text-info'>{ title_web } App</b> </p>
                        </div>
                    </div>
                </div>
            </ div>
            <div className='row'>
                <div className='col-md-4 mt-3'>
                    <div className="card bg-success bg-gradient text-light" >
                        <div className="card-body">
                            <h5 className="card-title fs-6">Your Balance</h5>
                            <p className="card-text fs-3 fw-bold">Rp. { numberWithCommas( currentBalance )  }</p>
                        </div>
                    </div>
                </div>
                
                <div className='col-md-4 mt-3'>
                    <div className="card bg-info bg-gradient text-light" >
                        <div className="card-body">
                            <h5 className="card-title fs-6">Your Income</h5>
                            <p className="card-text fs-3 fw-bold">Rp. { numberWithCommas( currentIncome )  }</p>
                        </div>
                    </div>
                </div>
                
                <div className='col-md-4 mt-3'>
                    <div className="card bg-info bg-gradient text-light" >
                        <div className="card-body">
                            <h5 className="card-title fs-6">Your Expenses</h5>
                            <p className="card-text fs-3 fw-bold">Rp. { numberWithCommas( currentExpenses )  }</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-md-12'>
                    <div>
                        <Link className='btn btn-primary' to='/transaction'>Transactions</Link>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
