import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeMain from './screens/home/HomeMain';
import TransactionMain from './screens/transaction/TransactionMain';
import LoginMain from './screens/login/LoginMain';
import MidlewareGuest from './midlewares/MidlewareGuest';
import MidlewareAuth from './midlewares/MidlewareAuth';
import RegisterMain from './screens/register/RegisterMain';



function App() {
  return (
    <>
      <BrowserRouter>
            <Routes>
                  <Route path='/dashboard' element={ <MidlewareAuth> <HomeMain /> </MidlewareAuth> } />
                  <Route path='/transaction' element={<MidlewareAuth> <TransactionMain /> </MidlewareAuth> } />
                  <Route path='/' element={ <MidlewareGuest> <LoginMain /> </MidlewareGuest>} />
                  <Route path='/login' element={ <MidlewareGuest> <LoginMain /> </MidlewareGuest>} />
                  <Route path='/register' element={ <MidlewareGuest> <RegisterMain /> </MidlewareGuest>} />
            </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
