import React, { useState } from 'react'

function AppButtonMenu() {
    const [winWidth, setwinWidth] = useState(window.innerWidth); 

    if ( winWidth < 770 ) {
        return (
            <>
                <button className="navbar-toggler" type="button" data-bs-toggle="modal" data-bs-target="#menuModal" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>
            </>
          )
    
    }

  return (
    <>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
        </button>
    </>
  )
}

export default AppButtonMenu