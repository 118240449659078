import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AppButtonMenu from '../AppButtonMenu';
import { title_web } from '../Constants'

function NavbarV1(props) {
    const nav = useNavigate();
    const [showModal, setshowModal ] = useState(false);

    const handleLogout = () => {
        localStorage.clear();
        window.location.href = "/login";
    }

  return (
    <>
        <nav className="navbar navbar-expand-lg bg-light">
            <div className="container">
                <a className="navbar-brand" href="#">{ title_web }</a>
                <AppButtonMenu />
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {/* <li className="nav-item">
                        <a className="nav-link active" aria-current="page" href="#">Home</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" href="#">Link</a>
                        </li>
                        <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Dropdown
                        </a>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link disabled">Disabled</a>
                        </li> */}
                    </ul>
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Account
                            </a>
                            <ul className="dropdown-menu">
                                {
                                    localStorage.getItem('token') ?
                                    <>
                                        <li><a className="dropdown-item" href="#">Profile</a></li>
                                        <li><button type='button' className="dropdown-item" onClick={ handleLogout }>Logout</button></li>
                                    </>
                                    :
                                    <>
                                        <li><a className="dropdown-item" href="#">Login</a></li>
                                        <li><a className="dropdown-item" href="#">Register</a></li>
                                    </>
                                }
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>


        {/* MODAL */}

        <div className="modal fade" id="menuModal" tabIndex={-1} aria-labelledby="menuModal"  aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="menuModal"></h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                    <button type='button' className="dropdown-item" onClick={ handleLogout }>Logout</button>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>


    </>
  )
}

export default NavbarV1