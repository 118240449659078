import React from 'react';
import { MDBDataTable } from 'mdbreact';

const AppDatatable = (props) => {
  const data = {
    columns: props.columns,
    rows: props.rows
  };

  return (
    <MDBDataTable
      striped
      bordered
      hover
      loading="true"
      data={props.data}
    />
  );
}

export default AppDatatable;